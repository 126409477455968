import { apiURL } from "commons/apiURL";
import axios from "axios";

export const getBenchmarks = async (token) => {
  try {
    const response = await axios.get(apiURL + "/api/getBenchmarks", {
      headers: {
        "X-API-KEY": process.env.REACT_APP_API_KEY,
        "x-app-id": "prata",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "There was an error sending the request:",
      error.response?.data?.message
    );
    throw error;
  }
};

export const getPackageNames = async (token) => {
  try {
    const response = await axios.get(apiURL + "/api/getPackageNames", {
      headers: {
        "X-API-KEY": process.env.REACT_APP_API_KEY,
        "x-app-id": "prata",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "There was an error sending the request:",
      error.response?.data?.message
    );
    throw error;
  }
};

export const getPackageName = async (id, token) => {
  try {
    const response = await axios.get(apiURL + `/api/getPackageName/${id}`, {
      headers: {
        "X-API-KEY": process.env.REACT_APP_API_KEY,
        "x-app-id": "prata",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "There was an error sending the request:",
      error.response?.data?.message
    );
    throw error;
  }
};

export const addNewPackageName = async (obj, token) => {
  try {
    const response = await axios.post(apiURL + "/api/addPackageName", obj, {
      headers: {
        "X-API-KEY": process.env.REACT_APP_API_KEY,
        "x-app-id": "prata",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      "There was an error sending the request:",
      error.response.data.message
    );
    throw error;
  }
};

export const updatePackageName = async (id, body, token) => {
  try {
    const response = await axios.patch(
      apiURL + `/api/updatePackageName/${id}`,
      body,
      {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "There was an error sending the request:",
      error.response?.data?.message
    );
    throw error;
  }
};
