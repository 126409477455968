// src/layouts/pages/loan-packages/add/index.js
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loading from "components/Loading";
import { GetCurrentTime } from "commons/DateTime";
import { sqlInjectionKeywords } from "commons/FormCleansing";

//Stylesheets
import "../styles/leads.css";

// @mui material components
import {
  Grid,
  Card,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
  Typography,
  Modal,
} from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftDropzone from "components/SoftDropzone";
import Document from "examples/Icons/Document";

import RatesTypes from "data/RatesTypes";
import PropertyTypes from "data/PropertyTypes";
import PackageTypes from "data/PackageTypes";
import Nationalities from "data/Nationalities";
import ResidentialStatuses from "data/ResidentialStatuses";
import HousingTypes from "data/HousingTypes";

import axios from "axios";
import NotificationModal from "components/NotificationModal";
import UploadModal from "../../../../components/UploadModal.js";

// Sweet Alerts page components
import { apiURL } from "commons/apiURL";
import LeadStatuses from "data/LeadStatuses";
import { ConstructionOutlined } from "@mui/icons-material";
import { useAccessToken } from "auth/auth.helper";
import * as api from "api";

const dropzoneOptions = {
  addRemoveLinks: true,
  acceptedFiles: "image/*,.doc,.docx,.pdf,.xls,.xlsx",
  autoProcessQueue: false,
  maxFiles: 5,
  minSize: 0,
  maxSize: 5242880,
};

function EditLead(props) {
  const { accessToken } = useAccessToken();
  const user = props.user;
  const { leadId } = useParams();
  const navigate = useNavigate();
  const dropzoneRef = useRef(null);
  const setDropzoneRef = (ref) => {
    dropzoneRef.current = ref;
  };

  // States
  const [showModal, setShowModal] = useState(false);
  const [packageNames, setPackageNames] = useState([]);
  const [benchmarks, setBenchmarks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [consultants, setConsultants] = useState([]);
  const [bankers, setBankers] = useState([]);
  const [errors, setErrors] = useState({});
  const [thisLead, setThisLead] = useState({});
  const [activePackage, setActivePackage] = useState({});
  const [name, setName] = useState("");
  const [gender, setGender] = useState(null);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [nationalities, setNationalities] = useState(null);
  const [residentialStatus, setResidentialStatus] = useState(null);
  const [housingType, setHousingType] = useState(null);
  const [loanPropertyLocation, setLoanPropertyLocation] = useState(null);
  const [newPropertyType, setNewPropertyType] = useState(null);
  const [buc, setBuc] = useState(null);
  const [financingType, setFinancingType] = useState(null);
  const [loanAmount, setLoanAmount] = useState("");
  const [tenure, setTenure] = useState(0);
  const [newPropertyName, setNewPropertyName] = useState("");
  const [tempNewPropertyName, setTempNewPropertyName] = useState("");
  const [loanPackage, setLoanPackage] = useState(null);
  const [loanPackages, setLoanPackages] = useState(null);
  const [leadStatus, setLeadStatus] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null);
  const [assignedBanker, setAssignedBanker] = useState(null);
  const [files, setFiles] = useState([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reminder, setReminder] = useState(null);
  let filesArray = [];

  let userRole = user["https://sales.loansupermart.sg/roles"][0].toUpperCase();

  const handleSwitchChange = (event) => {
    setDeleteMode(event.target.checked);
  };

  const getConsultants = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/getConsultants`, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Lead found successfully:", response.data);
      let cns = [];
      response.data.forEach((c) => {
        cns.push({ value: c.user_id, label: c.name });
      });
      setConsultants(cns);
      if (cns.length > 0) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const getBankers = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/getBankers`, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Lead found successfully:", response.data);
      let cns = [];
      response.data.forEach((c) => {
        cns.push({ value: c.user_id, label: c.name });
      });
      setBankers(cns);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const handleDelete = (filename, i) => {
    if (!deleteFiles.includes(filename)) {
      // Filter out the file to be deleted
      const updatedFiles = files.filter((file) => file !== filename);
      // Update the state
      setFiles(updatedFiles);
      console.log(143, updatedFiles);
      // If you need to keep track of deleted files for backend processing
      setDeleteFiles((prevDeleteFiles) => [...prevDeleteFiles, filename]);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getLeadById(leadId);
      getLoanPackages();
      getConsultants();
      getBankers();
      getPackageNames();
      getBenchmarks();
    }
  }, [leadId, accessToken]);

  const getPackageNames = async () => {
    try {
      const data = await api.getPackageNames(accessToken);
      console.log("API Response:", data);
      if (data) {
        setPackageNames(
          data.packageNames.map((item) => ({
            id: item.package_name_id,
            name: item.package_name,
          }))
        );
      } else {
        console.error("Unexpected API response format", data);
      }
    } catch (error) {
      console.error("Failed to fetch package names:", error);
    }
  };

  const getBenchmarks = async () => {
    try {
      const data = await api.getBenchmarks(accessToken);
      console.log("API Response:", data);
      if (data) {
        setBenchmarks((prev) => [
          ...data.map((item, i) => ({
            value: item.rate_type,
            label: item.rate_name,
          })),
        ]);
      } else {
        console.error("Unexpected API response format", data);
      }
    } catch (error) {
      console.error("Failed to fetch package names:", error);
    }
  };

  const getLeadById = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/getLead/${leadId}`, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setThisLead(response.data);
      getPackageById(response.data.loan_package);
      setFormFields(response.data);
      console.log(response.data);
      return true;
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const deleteLeadById = async (leadId) => {
    try {
      const response = await axios.delete(
        `${apiURL}/api/deleteLead/${leadId}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "prata",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Lead deleted successfully:", response.data);
      setShowModal(true);
      setTimeout(() => {
        navigate("/pages/leads/fresh");
      }, 2000);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const setFormFields = (lead) => {
    setName(lead.name);
    setGender(lead.gender);
    setMobile(lead.mobile);
    setEmail(lead.email);
    setDob(lead.dob);
    setNationalities(lead.nationality);
    setResidentialStatus(lead.residential_status);
    setHousingType(lead.current_housing);
    setLoanPropertyLocation(lead.loan_property_location);
    setNewPropertyType(lead.new_property);
    setBuc(lead.buc);
    setFinancingType(lead.financing_type);
    setLoanAmount(lead.loan_amount);
    setTenure(lead.tenure_required);
    setNewPropertyName(lead.new_property_name);
    setLoanPackage({
      label: "",
      value: lead.loan_package,
    });
    setFiles(lead.docs_uploaded);
    setLeadStatus(lead.status);
    setAssignedTo(lead.assigned_to);
    setAssignedBanker(lead.assigned_banker);
    setReminder(lead.reminder);
  };

  const getPackageById = async (packageId) => {
    try {
      const response = await axios.get(
        `${apiURL}/api/getPackage/${packageId}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "prata",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setActivePackage(response.data[0]);
      console.log("Package found successfully:", response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const Gender = [
    { value: "FEMALE", label: "Female" },
    { value: "MALE", label: "Male" },
    { value: "UNKNOWN", label: "Unknown" },
  ];

  const options = useMemo(() => dropzoneOptions, []);

  const setOption = (obj, value) => {
    let option = obj.find((o) => o.value === value);
    if (option && option !== null && option !== undefined) {
      return { value: option.value, label: option.label };
    }
  };

  const matchingLabel = (obj, value) => {
    return obj.find((option) => option.value === value).label;
  };

  const matchingOption = (obj, value) => {
    return obj.find((option) => option.value === value);
  };

  const getRateWithBenchmark = (rate, benchmarkValue, rateCategory) => {
    const benchmarkLabel = benchmarks.find(
      (b) => b.value === benchmarkValue
    )?.label;
    if (rate) {
      if (rateCategory.toUpperCase() === "FIXED") {
        return `${rate}% ${benchmarkLabel || rateCategory.toUpperCase()}`;
      }
      return `${rate}% + ${benchmarkLabel || rateCategory.toUpperCase()}`;
    }
    return null;
  };

  function convertToInternationalCurrencySystem(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + "K"
      : Math.abs(Number(labelValue));
  }

  const getLoanPackages = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/getPackages`, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // console.log("Packages found successfully:", response.data);
      let packageList = [];
      let packageName = "";
      response.data.map((p) => {
        packageNames.map((P) => {
          if (p.package_name === P.value) {
            packageName = P.label;
          }
        });
        packageList.push({
          value: p.package_id,
          label:
            p.bank_name +
            " - " +
            packageName +
            " $" +
            convertToInternationalCurrencySystem(p.min_quantum),
        });
      });
      setLoanPackages(packageList);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const ViewPackage = (thisPackageId) => {
    navigate(`/pages/loan-packages/view/${thisPackageId}`);
  };

  const ViewDocument = async (objectName) => {
    try {
      const response = await axios.get(
        `${apiURL}/api/getObject/${objectName}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "prata",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      window.open(response.data.url, "_blank");
      // console.log("Lead found successfully:", response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const cleanForm = (value) => {
    return sqlInjectionKeywords.some((keyword) =>
      value.toUpperCase().includes(keyword.toUpperCase())
    );
  };

  const checkFloat = (value) => {
    const parts = String(value).split(".");
    if (parts.length === 2 && parts[1].length > 3) {
      return "Value must not have more than 3 decimal places.";
    } else if (!/^\d+(\.\d{1,3})?$/.test(value)) {
      return "Value must be a valid number with up to 3 decimal places.";
    } else if (value.length > 30) {
      return "Value cannot exceed 30 characters.";
    } else {
      return null;
    }
  };

  const checkInt = (value) => {
    if (!/^\d+$/.test(value)) {
      return "Value must be a valid number. Decimals are not allowed.";
    } else if (value.length > 20) {
      return "Value cannot exceed 20 characters.";
    } else {
      return null;
    }
  };

  const validDateLessThanToday = (value) => {
    const providedDate = new Date(value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (providedDate < today) {
      return false;
    }
    return true;
  };

  function isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
      return false;
    }

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) {
      return false;
    }

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  const validDate = (value) => {
    if (isValidDate(value)) {
      return "Please key in date in dd/mm/yyyy format";
    } else if (validDateLessThanToday(value)) {
      return "Date of birth cannot be after today";
    } else {
      return null;
    }
  };

  const validateMobile = (mobileNo) => {
    // ^[89]\d{7}$ - Starts with an '8' or '9' followed by exactly 7 digits
    const regex = /^[89]\d{7}$/;
    return regex.test(mobileNo);
  };

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return regex.test(email);
  }

  const validateFields = () => {
    console.log("Validating fields...");

    let newErrors = {};

    if (!name) {
      newErrors.name = "Name of Lead is required.";
    }
    if (!mobile) {
      newErrors.mobile = "Mobile No. of Lead is required.";
    } else if (mobile && !validateMobile(mobile)) {
      newErrors.mobile =
        "Enter a 8 digit Singapore mobile number starting with 8 or 9 only";
    }
    if (!email) {
      newErrors.email = "Email of Lead is required.";
    } else if (email && !validateEmail(email)) {
      newErrors.email = "Enter a valid email";
    }
    if (dob && cleanForm(dob)) {
      newErrors.dob = 'Text cannot contain "--", ";", "{", "}", "<", ">".';
    } else if (validDate(dob) !== null) {
      newErrors.dob = validDate(dob);
    }
    if (!gender) {
      newErrors.gender = "Gender of Lead is required.";
    }
    if (!nationalities) {
      newErrors.nationalities = "Nationality of Lead is required.";
    }
    if (!residentialStatus) {
      newErrors.residentialStatus = "Residential Status of Lead is required.";
    }
    if (!housingType) {
      newErrors.housingType = "Current Housing Type of Lead is required.";
    }
    if (!loanPropertyLocation) {
      newErrors.loanPropertyLocation =
        "Location of Property for loan application is required.";
    }
    if (!newPropertyType) {
      newErrors.newPropertyType = "Type of New Property is required.";
    }
    if (!buc) {
      newErrors.buc = "Status of Building Under Construction is required.";
    }
    if (!financingType) {
      newErrors.financingType = "Type of Financing is required.";
    }
    if (!loanAmount) {
      newErrors.loanAmount = "Loan Amount is required.";
    } else if (loanAmount && cleanForm(loanAmount)) {
      newErrors.loanAmount =
        'Text cannot contain "--", ";", "{", "}", "<", ">".';
    } else if (checkFloat(loanAmount) !== null) {
      newErrors.loanAmount = checkFloat(loanAmount);
    }
    if (!tenure) {
      newErrors.tenure = "Loan Tenure is required.";
    } else if (tenure) {
      if (checkInt(tenure) !== null) {
        newErrors.tenure = checkInt(tenure);
      }
    }
    if (!leadStatus) {
      newErrors.leadStatus = "Lead Status is required.";
    }
    if (!assignedTo) {
      newErrors.assignedTo = "Assigned to Consultant is required.";
    }
    if (!loanPackage) {
      newErrors.loanPackages = "Loan package is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if no errors
  };

  const validateLostFields = () => {
    console.log("Validating lost fields");

    let newErrors = {};

    if (!name) {
      newErrors.name = "Name of Lead is required.";
    }
    if (!mobile) {
      newErrors.mobile = "Mobile No. of Lead is required.";
    } else if (mobile && !validateMobile(mobile)) {
      newErrors.mobile =
        "Enter a 8 digit Singapore mobile number starting with 8 or 9 only";
    }
    if (!email) {
      newErrors.email = "Email of Lead is required.";
    } else if (email && !validateEmail(email)) {
      newErrors.email = "Enter a valid email";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if no errors
  };

  const deleteFileFromRepo = async (file) => {
    try {
      const response = await axios.delete(
        apiURL + `/api/deleteObject/${file}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "prata",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("File deleted successfully:", response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  function checkChangedDropdown(dropdown) {
    console.log("dropdown is", dropdown);

    if (dropdown && dropdown.value) {
      return dropdown.value;
    } else {
      return dropdown;
    }
  }

  const handleUpload = async (files) => {
    const formData = new FormData();
    let fileNamesArray = [];
    for (let file of files) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
      const day = String(today.getDate()).padStart(2, "0");
      const hours = String(today.getHours()).padStart(2, "0");
      const minutes = String(today.getMinutes()).padStart(2, "0");
      const seconds = String(today.getSeconds()).padStart(2, "0");
      const fileExtension = file.name.split(".").pop();
      const fileNameSegs = file.name.split(".");
      let newFileName = "";
      for (let i = 0; i < fileNameSegs.length; i++) {
        fileNameSegs[i] = fileNameSegs[i].replace(/[^a-zA-Z0-9 ]/g, "");
        fileNameSegs[i] = fileNameSegs[i].replace(/ /g, "-");
        fileNameSegs[i] = fileNameSegs[i].substring(0, 12);
        newFileName = newFileName + fileNameSegs[i];
      }
      newFileName =
        newFileName +
        `${year}-${month}-${day}_${hours}:${minutes}:${seconds}.` +
        fileExtension;
      fileNamesArray.push(newFileName);
      let newFile = new File([file], newFileName, { type: file.type });
      formData.append("files", newFile);
      thisLead.new_files = formData;
    }
  };

  const update = async () => {
    const noErrors =
      leadStatus?.value !== "L" && leadStatus !== "L"
        ? validateFields()
        : validateLostFields();
    let files = thisLead.docs_uploaded;
    let qfiles = [];
    let updatedLead = {};
    console.log("errors", noErrors, leadStatus);

    if (noErrors) {
      setIsLoading(true);
      let qfilenames = [];
      if (thisLead.new_files !== undefined && thisLead.new_files.length !== 0) {
        for (let value of thisLead.new_files.values()) {
          if (value instanceof File) {
            qfilenames.push(value.name);
          }
        }
      }
      if (qfiles.length > 0) {
        files = files.concat(qfilenames);
      }
      // Delete the deleted files from repository
      if (deleteFiles.length > 0) {
        deleteFiles.forEach((f) => {
          deleteFileFromRepo(f);
          files = files.filter((file) => file !== f);
        });
        setIsLoading(false);
      }
      updatedLead = {
        lead_id: thisLead.lead_id,
        name: name,
        gender: checkChangedDropdown(gender) || "",
        mobile: mobile,
        email: email,
        dob: dob || "",
        nationality: checkChangedDropdown(nationalities) || "",
        residential_status: checkChangedDropdown(residentialStatus) || "",
        current_housing: checkChangedDropdown(housingType) || "",
        loan_property_location:
          checkChangedDropdown(loanPropertyLocation) || "",
        new_property: checkChangedDropdown(newPropertyType) || "",
        buc: checkChangedDropdown(buc) || "",
        financing_type: checkChangedDropdown(financingType) || "",
        loan_amount: loanAmount || "",
        tenure_required: tenure || "",
        new_property_name: newPropertyName || "",
        loan_package: loanPackage.value || "",
        docs_uploaded: files,
        status: checkChangedDropdown(leadStatus) || "",
        assigned_to: checkChangedDropdown(assignedTo) || "",
        assigned_banker: checkChangedDropdown(assignedBanker) || "",
        verified_data: thisLead.verified_data,
        datetime_created: GetCurrentTime(),
        datetime_updated: GetCurrentTime(),
        deleted_files: deleteFiles,
        new_files: qfilenames,
        reminder: reminder,
      };
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${apiURL}/api/updateLead`,
          updatedLead,
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_API_KEY,
              "x-app-id": "prata",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.log(thisLead.new_files);
        console.log(`Lead updated successfully: ${response}`);
        // const updatedLeadId = response.data.body;
        if (thisLead.new_files) {
          console.log(`Uploading files.....`);
          try {
            const response = await axios.post(
              `${apiURL}/api/files-upload`,
              thisLead.new_files,
              {
                headers: {
                  "X-API-KEY": process.env.REACT_APP_API_KEY,
                  "x-app-id": "prata",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            console.log("File uploaded successfully:", response.data);
            if (response.data) {
              setTimeout(() => {
                setIsLoading(false);
                navigate(`/pages/leads/view/${leadId}`);
              }, 1200);
            }
          } catch (error) {
            console.error(
              "There was an error sending the request:",
              error.response ? error.response.data.message : error.message
            );
          }
        } else {
          setTimeout(() => {
            setIsLoading(false);
            navigate(`/pages/leads/view/${leadId}`);
          }, 1200);
        }
      } catch (error) {
        console.error(
          "There was an error sending the request:",
          error.response ? error.response.data.message : error.message
        );
      }
    }
  };

  const back = () => {
    navigate(-1);
  };

  useEffect(() => {
    console.log("assignedTo is", assignedTo);
  }, [assignedTo]);

  useEffect(() => {
    console.log("assignedBanker is", assignedBanker);
  }, [assignedBanker]);

  return (
    <DashboardLayout>
      <DashboardNavbar absolutedisabled={true} />
      {showModal && (
        <NotificationModal
          setShowModal={setShowModal}
          message={"Updated Successfully"}
          disabled={true}
        />
      )}
      <Modal
        open={deleteModal}
        // onClose={() => setDeleteModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="delete-confirmation-title"
            variant="h4"
            component="h2"
            mb={2}
          >
            Confirm Delete
          </Typography>
          <Typography
            variant="body2"
            id="delete-confirmation-description"
            mb={3}
          >
            Are you sure you want to delete this lead?
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
          >
            <SoftButton
              component={""}
              onClick={() => setDeleteModal(false)}
              variant="gradient"
              color="light"
            >
              Cancel
            </SoftButton>
            <SoftButton
              component={""}
              onClick={() => deleteLeadById(leadId)}
              variant="gradient"
              color="info"
            >
              Confirm
            </SoftButton>
          </Box>
        </Box>
      </Modal>
      {thisLead && consultants !== undefined && !isLoading ? (
        <SoftBox mt={5} mb={3}>
          <Card style={{ padding: "28px 40px" }}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={12} xl={12}>
                <Grid item xs={6} md={6} xl={6}>
                  {thisLead.verified_data ? (
                    <SoftBadge
                      badgeContent="MyInfo Data"
                      color="success"
                      style={{ float: "left" }}
                      sx={{ mr: 1 }}
                      container
                    />
                  ) : (
                    <SoftBadge
                      badgeContent="Self-input Data"
                      color="secondary"
                      style={{ float: "left" }}
                      sx={{ mr: 1 }}
                      container
                    />
                  )}
                </Grid>
                <Grid
                  container
                  item
                  spacing={3}
                  justifyContent="flex-end"
                  style={{ float: "right" }}
                >
                  <Grid item style={{ float: "right" }}>
                    <SoftButton
                      component={""}
                      onClick={() => back()}
                      variant="gradient"
                      color="error"
                    >
                      Back
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => update()}
                      variant="gradient"
                      color="info"
                    >
                      Save
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Name*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    size="small"
                    disabled={thisLead.verified_data ? true : false}
                  />
                  {errors.name && (
                    <SoftTypography variant="body2" color="error">
                      {errors.name}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Mobile Number*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    size="small"
                    disabled={thisLead.verified_data ? true : false}
                  />
                  {errors.mobile && (
                    <SoftTypography variant="body2" color="error">
                      {errors.mobile}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Email*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    size="small"
                    disabled={thisLead.verified_data ? true : false}
                  />
                  {errors.email && (
                    <SoftTypography variant="body2" color="error">
                      {errors.email}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Date of Birth*
                  </SoftTypography>
                  <SoftInput
                    type="date"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    size="small"
                    disabled={thisLead.verified_data ? true : false}
                  />
                  {errors.dob && (
                    <SoftTypography variant="body2" color="error">
                      {errors.dob}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Gender*
                  </SoftTypography>
                  {thisLead.gender && thisLead.verified_data ? (
                    <SoftInput
                      type="text"
                      value={matchingLabel(Gender, gender)}
                      size="small"
                      disabled={thisLead.verified_data ? true : false}
                    />
                  ) : (
                    <SoftSelect
                      options={Gender}
                      onChange={(event) => setGender(event)}
                      value={setOption(Gender, gender)}
                      size="small"
                    />
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Nationality*
                  </SoftTypography>
                  {thisLead.nationality && thisLead.verified_data ? (
                    <SoftInput
                      type="text"
                      value={matchingLabel(Nationalities, nationalities)}
                      size="small"
                      disabled={thisLead.verified_data ? true : false}
                    />
                  ) : (
                    <SoftSelect
                      options={Nationalities}
                      onChange={(event) => setNationalities(event)}
                      value={setOption(Nationalities, nationalities)}
                      size="small"
                    />
                  )}
                  {errors.nationalities && (
                    <SoftTypography variant="body2" color="error">
                      {errors.nationalities}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Residential Status*
                  </SoftTypography>
                  {thisLead.residential_status && thisLead.verified_data ? (
                    <SoftInput
                      type="text"
                      value={matchingLabel(
                        ResidentialStatuses,
                        residentialStatus
                      )}
                      size="small"
                      disabled={thisLead.verified_data ? true : false}
                    />
                  ) : (
                    <SoftSelect
                      options={ResidentialStatuses}
                      onChange={(event) => setResidentialStatus(event)}
                      value={setOption(ResidentialStatuses, residentialStatus)}
                      size="small"
                    />
                  )}
                  {errors.residentialStatus && (
                    <SoftTypography variant="body2" color="error">
                      {errors.residentialStatus}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Current Housing*
                  </SoftTypography>
                  {thisLead.current_housing && thisLead.verified_data ? (
                    <SoftInput
                      type="text"
                      value={matchingLabel(
                        HousingTypes,
                        parseInt(thisLead.current_housing)
                      )}
                      size="small"
                      disabled={thisLead.verified_data ? true : false}
                    />
                  ) : (
                    <SoftSelect
                      options={HousingTypes}
                      onChange={(event) => setHousingType(event)}
                      value={setOption(HousingTypes, parseInt(housingType))}
                      size="small"
                    />
                  )}
                  {errors.housingType && (
                    <SoftTypography variant="body2" color="error">
                      {errors.housingType}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Location of Housing for Loan*
                  </SoftTypography>
                  <SoftSelect
                    options={[
                      { value: null, label: "" },
                      { value: "local", label: "Local" },
                      { value: "overseas", label: "Overseas" },
                    ]}
                    onChange={(event) =>
                      setLoanPropertyLocation({
                        value: event.value,
                        label: event.label,
                      })
                    }
                    value={matchingOption(
                      [
                        { value: null, label: "" },
                        { value: "local", label: "Local" },
                        { value: "overseas", label: "Overseas" },
                      ],
                      loanPropertyLocation
                    )}
                    size="small"
                  />
                  {errors.loanPropertyLocation && (
                    <SoftTypography variant="body2" color="error">
                      {errors.loanPropertyLocation}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    New Property Type*
                  </SoftTypography>
                  <SoftSelect
                    options={PropertyTypes}
                    onChange={(event) =>
                      setNewPropertyType({
                        value: event.value,
                        label: event.label,
                      })
                    }
                    value={matchingOption(
                      PropertyTypes,
                      parseInt(newPropertyType)
                    )}
                    size="small"
                  />
                  {errors.newPropertyType && (
                    <SoftTypography variant="body2" color="error">
                      {errors.newPropertyType}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Building Under Construction*
                  </SoftTypography>
                  <SoftSelect
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                    onChange={(event) =>
                      setBuc({ value: event.value, label: event.label })
                    }
                    value={matchingOption(
                      [
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                        { value: "any", label: "Any" },
                      ],
                      buc
                    )}
                    size="small"
                  />
                  {errors.buc && (
                    <SoftTypography variant="body2" color="error">
                      {errors.buc}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Type of Financing Required*
                  </SoftTypography>
                  <SoftSelect
                    options={[
                      {
                        value: "New Home Purchase",
                        label: "New Home Purchase",
                      },
                      { value: "Refinance", label: "Refinance" },
                      { value: "Cash Out", label: "Cash-out" },
                    ]}
                    onChange={(event) =>
                      setFinancingType({
                        value: event.value,
                        label: event.label,
                      })
                    }
                    value={matchingOption(
                      [
                        {
                          value: "New Home Purchase",
                          label: "New Home Purchase",
                        },
                        { value: "Refinance", label: "Refinance" },
                        { value: "Cash Out", label: "Cash-out" },
                      ],
                      financingType
                    )}
                    size="small"
                  />
                  {errors.financingType && (
                    <SoftTypography variant="body2" color="error">
                      {errors.financingType}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Loan Amount*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={loanAmount}
                    onChange={(e) => setLoanAmount(e.target.value)}
                    size="small"
                  />
                  {errors.loanAmount && (
                    <SoftTypography variant="body2" color="error">
                      {errors.loanAmount}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Tenure Required (Months)*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={tenure}
                    onChange={(e) => setTenure(e.target.value)}
                    size="small"
                  />
                  {errors.tenure && (
                    <SoftTypography variant="body2" color="error">
                      {errors.tenure}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    New Property Name
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={
                      newPropertyName === "Skip Question" ? "" : newPropertyName
                    }
                    onChange={(e) => setNewPropertyName(e.target.value)}
                    size="small"
                  />
                  {errors.newPropertyName && (
                    <SoftTypography variant="body2" color="error">
                      {errors.newPropertyName}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Status*
                  </SoftTypography>
                  <SoftSelect
                    options={LeadStatuses}
                    onChange={(event) =>
                      setLeadStatus({ value: event.value, label: event.label })
                    }
                    value={matchingOption(LeadStatuses, leadStatus)}
                    size="small"
                  />
                  {errors.leadStatus && (
                    <SoftTypography variant="body2" color="error">
                      {errors.leadStatus}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Assigned to*
                  </SoftTypography>
                  {consultants !== undefined && consultants !== null && (
                    <SoftSelect
                      options={consultants}
                      onChange={(event) => setAssignedTo(event)}
                      value={setOption(
                        consultants,
                        userRole == "CONSULTANTS" ? user.sub : assignedTo
                      )}
                      size="small"
                      isDisabled={userRole == "CONSULTANTS"}
                    />
                  )}
                  {errors.assignedTo && (
                    <SoftTypography variant="body2" color="error">
                      {errors.assignedTo}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Assign Banker
                  </SoftTypography>
                  {bankers !== undefined && bankers !== null && (
                    <SoftSelect
                      options={bankers}
                      onChange={(event) => setAssignedBanker(event)}
                      value={setOption(bankers, assignedBanker)}
                      size="small"
                    />
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Remind on
                  </SoftTypography>
                  <SoftInput
                    type="date"
                    value={reminder}
                    onChange={(e) => setReminder(e.target.value)}
                    size="small"
                    // disabled={thisLead.verified_data}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Selected Loan Package*
                  </SoftTypography>
                  {loanPackages !== undefined && loanPackages !== null && (
                    <SoftSelect
                      options={[
                        {
                          label: "N.A",
                          value: null,
                        },
                        ...loanPackages,
                      ]}
                      onChange={(event) => {
                        setLoanPackage(event);
                        getPackageById(event.value);
                      }}
                      value={setOption(loanPackages, loanPackage.value)}
                      style={{ zIndex: 999999 }}
                      size="small"
                    />
                  )}
                  {loanPackage?.value && (
                    <Grid item xs={12} md={12} xl={12} mt={3}>
                      <Card
                        style={{
                          padding: "20px",
                          boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <SoftButton
                          component={""}
                          onClick={() => ViewPackage(activePackage.package_id)}
                          variant="gradient"
                          style={{
                            backgroundImage:
                              "linear-gradient(to top left, #173358, #91d4c7)",
                            color: "white",
                          }}
                          size="large"
                        >
                          {packageNames.find(
                            (p) => p.id == activePackage?.package_name
                          )?.name || "Invalid package"}
                        </SoftButton>
                        <SoftTypography
                          mt={1}
                          variant="caption"
                          style={{ color: "gray" }}
                        >
                          &nbsp;
                        </SoftTypography>
                        <Grid item xs={12} md={12} xl={12}>
                          <SoftBadge
                            badgeContent={activePackage?.bank_name}
                            color="primary"
                            style={{ float: "left", zIndex: 0 }}
                            sx={{ mr: 1 }}
                            container
                          />
                          {activePackage?.is_active == 1 && (
                            <SoftBadge
                              badgeContent="Active"
                              color="secondary"
                              style={{ float: "left", zIndex: 0 }}
                              sx={{ mr: 1 }}
                              container
                            />
                          )}
                        </Grid>
                        <SoftTypography mt={1} variant="h6">
                          Year 1:{" "}
                          {activePackage?.y1_rates_cat == "hybrid"
                            ? "HYBRID"
                            : getRateWithBenchmark(
                                activePackage?.y1_rates,
                                activePackage?.y1_rates_benchmark,
                                activePackage?.y1_rates_cat
                              )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 2:{" "}
                          {activePackage?.y2_rates_cat == "hybrid"
                            ? "HYBRID"
                            : getRateWithBenchmark(
                                activePackage?.y2_rates,
                                activePackage?.y2_rates_benchmark,
                                activePackage?.y2_rates_cat
                              )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 3:{" "}
                          {activePackage?.y3_rates ? (
                            activePackage?.y3_rates_cat == "hybrid" ? (
                              "HYBRID"
                            ) : (
                              getRateWithBenchmark(
                                activePackage?.y3_rates,
                                activePackage?.y3_rates_benchmark,
                                activePackage?.y3_rates_cat
                              )
                            )
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 4:{" "}
                          {activePackage?.y4_rates ? (
                            activePackage?.y4_rates_cat == "hybrid" ? (
                              "HYBRID"
                            ) : (
                              getRateWithBenchmark(
                                activePackage?.y4_rates,
                                activePackage?.y4_rates_benchmark,
                                activePackage?.y4_rates_cat
                              )
                            )
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 5:{" "}
                          {activePackage?.y5_rates ? (
                            activePackage?.y5_rates_cat == "hybrid" ? (
                              "HYBRID"
                            ) : (
                              getRateWithBenchmark(
                                activePackage?.y5_rates,
                                activePackage?.y5_rates_benchmark,
                                activePackage?.y5_rates_cat
                              )
                            )
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Thereafter:{" "}
                          {activePackage?.thereafter_rates_cat == "hybrid"
                            ? "HYBRID"
                            : getRateWithBenchmark(
                                activePackage?.thereafter_rates,
                                activePackage?.thereafter_rates_benchmark,
                                activePackage?.thereafter_rates_cat
                              )}
                        </SoftTypography>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12} xl={12} mt={3}>
                            {activePackage?.legal_subsidy_cash_rebate_amount !==
                            "" ? (
                              <SoftBadgeDot
                                badgeContent="Free Legal Subsidy"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {activePackage?.fire_insurance_description !==
                            "" ? (
                              <SoftBadgeDot
                                badgeContent="Fire Insurance"
                                mr={1}
                                color="error"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {activePackage?.free_valuation !== "" ? (
                              <SoftBadgeDot
                                badgeContent="Fire Valuation"
                                mr={1}
                                color="success"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {activePackage?.free_repricing_conversion !== "" ? (
                              <SoftBadgeDot
                                badgeContent="Free Repricing"
                                mr={1}
                                color="primary"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          xl={12}
                          mt={3}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <SoftButton
                            component={""}
                            onClick={() =>
                              ViewPackage(activePackage?.package_id)
                            }
                            variant="gradient"
                            color="error"
                          >
                            View Full Package
                          </SoftButton>
                        </Grid>
                      </Card>
                    </Grid>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography
                    variant="h6"
                    style={{ marginBottom: "5px", float: "none" }}
                  >
                    <SoftTypography
                      style={{ float: "left", marginRight: "7px" }}
                    >
                      Documents
                    </SoftTypography>
                    <br />
                    <SoftTypography
                      variant="caption"
                      color="error"
                      style={{ float: "left" }}
                    >
                      (Allow pop-up windows to view files)
                    </SoftTypography>
                  </SoftTypography>
                  <SoftBox>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={deleteMode}
                          onChange={handleSwitchChange}
                          inputProps={{ "aria-label": "controlled" }}
                          style={{ color: deleteMode ? "green" : "" }}
                        />
                      }
                      label={
                        deleteMode
                          ? "Delete Mode (Click to View)"
                          : "View Mode (Click to Delete)"
                      }
                      style={{ float: "left" }}
                    />
                    <br />
                    <br />
                    <SoftTypography
                      variant="caption"
                      color="error"
                      style={{ float: "left" }}
                    >
                      Please upload only 1 file at a time
                    </SoftTypography>
                    <br />
                    {thisLead && files ? (
                      files.length > 0 ? (
                        files.map((doc, i) =>
                          deleteMode ? (
                            <div
                              key={i}
                              onClick={() => handleDelete(doc, i)}
                              className="doc-wrapper"
                            >
                              <Tooltip title={doc}>
                                <div key={doc}>
                                  <Document size="30px" color="success" />
                                </div>
                              </Tooltip>
                              {deleteMode && (
                                <>
                                  <Tooltip title="Click to delete">
                                    <div className="delete-file-icon">
                                      <span className="delete-icon">x</span>
                                    </div>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              key={i}
                              onClick={() => ViewDocument(doc)}
                              className="doc-wrapper"
                            >
                              <Tooltip title={doc}>
                                <div>
                                  <Document size="30px" color="success" />
                                </div>
                              </Tooltip>
                            </div>
                          )
                        )
                      ) : (
                        <SoftTypography variant="caption">
                          No Documents Uploaded Yet
                        </SoftTypography>
                      )
                    ) : null}
                  </SoftBox>
                  <SoftBox mt={3}>
                    <UploadModal onUpload={handleUpload} setFiles={setFiles} />
                    {/* {setDropzoneRef !== undefined && (
                                            <SoftDropzone options={options}
                                                setDropzoneRef={setDropzoneRef}
                                                setFiles={setFiles} />
                                        )} */}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              style={{ marginTop: "20px" }}
            >
              <Grid item>
                <SoftButton
                  component={""}
                  onClick={() => setDeleteModal(true)}
                  variant="gradient"
                  color="warning"
                >
                  Delete
                </SoftButton>
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Grid container spacing={3} justifyContent="flex-end">
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => back()}
                      variant="gradient"
                      color="error"
                    >
                      Back
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => update()}
                      variant="gradient"
                      color="info"
                    >
                      Save
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </SoftBox>
      ) : (
        <Loading />
      )}
    </DashboardLayout>
  );
}

export default EditLead;
