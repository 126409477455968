import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";

export const useAccessToken = () => {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
    useAuth0();
  const [accessToken, setAccessToken] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          setAccessToken(token);
        }
      } catch (e) {
        setError(e);
        if (e.error === "login_required") {
          loginWithRedirect(); // Redirect if no session found
        }
      }
    };

    getToken();
  }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  return { accessToken, error };
};
