// src/layouts/pages/loan-packages/add/index.js
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loading from "components/Loading";

// @mui material components
import {
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  Checkbox,
  Switch,
  Grid,
  Card,
} from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import Benchmarks from "data/Benchmarks";
import RatesTypes from "data/RatesTypes";
import PropertyTypes from "data/PropertyTypes";
import PackageTypes from "data/PackageTypes";

import axios from "axios";
import NotificationModal from "components/NotificationModal";

// Sweet Alerts page components
import { apiURL } from "commons/apiURL";
import * as api from "api";
import { useAccessToken } from "auth/auth.helper";

function ViewPackage(props) {
  const user = props.user;
  const { packageId } = useParams();
  const navigate = useNavigate();
  const { accessToken } = useAccessToken();

  // States
  const [packageNames, setPackageNames] = useState([]);
  const [benchmarks, setBenchmarks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  // states only for view package
  const [thisPackage, setThisPackage] = useState(null);

  let userRole = user["https://sales.loansupermart.sg/roles"][0].toUpperCase();
  userRole = userRole.replace("BANKER-", "");

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  const getPackageById = async () => {
    try {
      const response = await axios.get(
        `${apiURL}/api/getPackage/${packageId}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "prata",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setThisPackage(response.data[0]);
      console.log("Package found successfully:", response.data);
      console.log(thisPackage);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  const duplicatePackage = async () => {
    let newPackage = thisPackage;
    delete newPackage.package_id;
    try {
      const response = await axios.post(
        apiURL + "/api/addPackage",
        newPackage,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "prata",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("Packages added successfully:", response.data);
      console.log(newPackage);
      navigate("/pages/loan-packages/all");
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response.data.message
      );
    }
  };

  useEffect(() => {
    if (accessToken) {
      getPackageNames();
      getBenchmarks();
      getPackageById(packageId);
    }
  }, [packageId, accessToken]);

  const getPackageNames = async (page) => {
    try {
      const data = await api.getPackageNames(accessToken);
      console.log("API Response:", data);
      if (data) {
        setPackageNames(
          data.packageNames.map((item) => ({
            id: item.package_name_id,
            name: item.package_name,
          }))
        );
      } else {
        console.error("Unexpected API response format", data);
      }
    } catch (error) {
      console.error("Failed to fetch package names:", error);
    }
  };

  const getBenchmarks = async () => {
    try {
      const response = await axios.get(apiURL + "/api/getBenchmarks", {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
        },
      });

      setBenchmarks((prev) => [
        ...Benchmarks,
        ...response.data.map((item, i) => ({
          value: item.rate_type,
          label: item.rate_name,
        })),
      ]);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response?.data?.message
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolutedisabled={true} />
      {showModal && (
        <NotificationModal
          setShowModal={setShowModal}
          message={"Add Loan Package Successfully"}
          disabled={true}
        />
      )}
      {thisPackage ? (
        <SoftBox mt={5} mb={3}>
          <Card style={{ padding: "28px 40px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={6}>
                &nbsp;
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <Grid container spacing={3} justifyContent="flex-end">
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => cancel()}
                      variant="gradient"
                      color="error"
                    >
                      Cancel
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() =>
                        navigate(`/pages/loan-packages/edit/${packageId}`)
                      }
                      variant="gradient"
                      color="info"
                    >
                      Edit
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => duplicatePackage()}
                      variant="gradient"
                      color="info"
                    >
                      Duplicate
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} xl={12}>
                <SoftBox mb={2}>
                  <SoftTypography
                    variant="overline"
                    color="error"
                    style={{ marginBottom: "5px" }}
                  >
                    Leave blank if any of the fields are not applicable. Fields
                    marked with * are mandatory.
                  </SoftTypography>
                  <SoftTypography variant="h6" style={{ marginBottom: "3px" }}>
                    Package Name*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Loan Package Name"
                    size="small"
                    value={
                      packageNames.find(
                        (item) => item.id == thisPackage?.package_name
                      )?.name || ""
                    }
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Package Type*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Package Type"
                    size="small"
                    value={
                      PackageTypes.find(
                        (item) => item.value == thisPackage?.package_type
                      )?.label || ""
                    }
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Property Type*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Property Type"
                    size="small"
                    value={
                      PropertyTypes.find(
                        (item) => item.value == thisPackage?.property_type
                      )?.label || ""
                    }
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Property Location*
                  </SoftTypography>
                  <FormControl>
                    <RadioGroup
                      disabled={true}
                      row
                      value={thisPackage.property_location || ""}
                      name="property-location"
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label={"Singapore"}
                        sx={{ marginLeft: 0 }}
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label={"Malaysia"}
                        sx={{ marginLeft: 0 }}
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio />}
                        label={"Australia"}
                        sx={{ marginLeft: 0 }}
                      />
                      <FormControlLabel
                        value={4}
                        control={<Radio />}
                        label={"Japan"}
                        sx={{ marginLeft: 0 }}
                      />
                      <FormControlLabel
                        value={5}
                        control={<Radio />}
                        label={"Thailand"}
                        sx={{ marginLeft: 0 }}
                      />
                      <FormControlLabel
                        value={6}
                        control={<Radio />}
                        label={"United Kingdom"}
                        sx={{ marginLeft: 0 }}
                      />
                      <FormControlLabel
                        value={7}
                        control={<Radio />}
                        label={"United States"}
                        sx={{ marginLeft: 0 }}
                      />
                    </RadioGroup>
                  </FormControl>
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Bank*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Bank"
                    size="small"
                    value={thisPackage.bank_name || ""}
                    disabled={true}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <SoftBox mb={2} ml={2}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={thisPackage.is_active == "1"}
                          onChange={handleSwitchChange}
                          inputProps={{ "aria-label": "controlled" }}
                          style={{ color: checked ? "green" : "" }}
                        />
                      }
                      label="Active*"
                    />
                  </FormGroup>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Year 1 Rates Type*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Year 1 Rates Type"
                    size="small"
                    value={
                      RatesTypes.find(
                        (item) => item.value == thisPackage?.y1_rates_cat
                      )?.label || ""
                    }
                    disabled={true}
                  />
                </SoftBox>
                {thisPackage.y1_rates_cat === "hybrid" && (
                  <SoftBox
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "6px",
                      borderRadius: "8px",
                    }}
                  >
                    {/* **********Hybrid 1********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 1 Rates Type Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 1 Rates Type Hybrid 1"
                        value={"Fixed"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 1 Rates in % Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 1 Rates in %"
                        value={thisPackage.y1h1_rates}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 1 Rates Hybrid 1 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 1 Rates Hybrid 1 for % of loan"
                        value={thisPackage.y1h1_portion}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    {/* **********Hybrid 2********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 1 Rates Type Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 1 Rates Type Hybrid 1"
                        value={"Floating"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 1 Rates in % Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 1 Rates in %"
                        value={thisPackage.y1h2_rates || ""}
                        disabled={true}
                        size="small"
                      />

                      <SoftTypography variant="caption" color="success">
                        {thisPackage.y1h1_rates
                          ? thisPackage.y1h1_rates + "% "
                          : "Year 1 Rates in % "}
                        +{" "}
                        {thisPackage.y1h2_benchmark
                          ? thisPackage.y1h2_benchmark
                          : "Year 1 Rates Benchmark*"}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 1 Rates Benchmark Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        value={
                          benchmarks.find(
                            (item) => item.value == thisPackage.y1h2_benchmark
                          )?.label
                        }
                        size="small"
                        placeholder="Year 1 Rates Benchmark Hybrid 2"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 1 Rates Hybrid 2 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 1 Rates in %"
                        value={thisPackage.y1h2_portion || ""}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                  </SoftBox>
                )}
                {thisPackage.y1_rates_cat !== "hybrid" && (
                  <>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 1 Rates in %*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 1 Rates in %"
                        value={thisPackage.y1_rates || ""}
                        size="small"
                        disabled={true}
                      />

                      {thisPackage.y1_rates_cat === "floating" && (
                        <SoftTypography variant="caption" color="success">
                          {thisPackage.y1_rates + "% "}+{" "}
                          {
                            benchmarks.find(
                              (item) =>
                                item.value == thisPackage.y1_rates_benchmark
                            )?.label
                          }
                        </SoftTypography>
                      )}
                    </SoftBox>
                    {thisPackage.y1_rates_cat === "floating" && (
                      <SoftBox mb={2}>
                        <SoftTypography
                          variant="h6"
                          style={{ marginBottom: "5px" }}
                        >
                          Year 1 Rates Benchmark*
                        </SoftTypography>
                        <SoftInput
                          type="text"
                          value={
                            benchmarks.find(
                              (item) =>
                                item.value == thisPackage.y1_rates_benchmark
                            )?.label || ""
                          }
                          size="small"
                          disabled={true}
                        />
                      </SoftBox>
                    )}
                  </>
                )}
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Year 2 Rates Type*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Year 2 Rates Type"
                    size="small"
                    value={
                      RatesTypes.find(
                        (item) => item.value == thisPackage?.y2_rates_cat
                      )?.label || ""
                    }
                    disabled={true}
                  />
                </SoftBox>
                {thisPackage.y2_rates_cat === "hybrid" && (
                  <SoftBox
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "6px",
                      borderRadius: "8px",
                    }}
                  >
                    {/* **********Hybrid 1********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 2 Rates Type Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 2 Rates Type Hybrid 1"
                        value={"Fixed"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 2 Rates in % Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 2 Rates in %"
                        value={thisPackage.y2h1_rates}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 2 Rates Hybrid 1 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 2 Rates Hybrid 1 for % of loan"
                        value={thisPackage.y2h1_portion}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    {/* **********Hybrid 2********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 2 Rates Type Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 2 Rates Type Hybrid 2"
                        value={"Floating"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 2 Rates in % Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 2 Rates in %"
                        value={thisPackage.y2h2_rates || ""}
                        disabled={true}
                        size="small"
                      />

                      <SoftTypography variant="caption" color="success">
                        {thisPackage.y2h1_rates
                          ? thisPackage.y2h1_rates + "% "
                          : "Year 2 Rates in % "}
                        +{" "}
                        {thisPackage.y2h2_benchmark
                          ? thisPackage.y2h2_benchmark
                          : "Year 2 Rates Benchmark*"}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 2 Rates Benchmark Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        value={
                          benchmarks.find(
                            (item) => item.value == thisPackage.y2h2_benchmark
                          )?.label
                        }
                        size="small"
                        placeholder="Year 2 Rates Benchmark Hybrid 2"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 2 Rates Hybrid 2 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 2 Rates in %"
                        value={thisPackage.y2h2_portion || ""}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                  </SoftBox>
                )}
                {thisPackage.y2_rates_cat !== "hybrid" && (
                  <>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 2 Rates in %*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 2 Rates in %"
                        value={thisPackage.y2_rates || ""}
                        size="small"
                        disabled={true}
                      />
                      {thisPackage.y2_rates_cat === "floating" && (
                        <SoftTypography variant="caption" color="success">
                          {thisPackage.y2_rates + "% "}+{" "}
                          {
                            benchmarks.find(
                              (item) =>
                                item.value == thisPackage.y2_rates_benchmark
                            )?.label
                          }
                        </SoftTypography>
                      )}
                    </SoftBox>
                    {thisPackage.y2_rates_cat === "floating" && (
                      <SoftBox mb={2}>
                        <SoftTypography
                          variant="h6"
                          style={{ marginBottom: "5px" }}
                        >
                          Year 2 Rates Benchmark*
                        </SoftTypography>
                        <SoftInput
                          type="text"
                          value={
                            benchmarks.find(
                              (item) =>
                                item.value == thisPackage.y2_rates_benchmark
                            )?.label || ""
                          }
                          size="small"
                          disabled={true}
                        />
                      </SoftBox>
                    )}
                  </>
                )}
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Year 3 Rates Type
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Year 3 Rates Type"
                    size="small"
                    value={
                      RatesTypes.find(
                        (item) => item.value == thisPackage?.y3_rates_cat
                      )?.label || ""
                    }
                    disabled={true}
                  />
                </SoftBox>
                {thisPackage.y3_rates_cat === "hybrid" && (
                  <SoftBox
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "6px",
                      borderRadius: "8px",
                    }}
                  >
                    {/* **********Hybrid 1********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 3 Rates Type Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 3 Rates Type Hybrid 1"
                        value={"Fixed"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 3 Rates in % Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 3 Rates in %"
                        value={thisPackage.y3h1_rates}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 3 Rates Hybrid 1 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 3 Rates Hybrid 1 for % of loan"
                        value={thisPackage.y3h1_portion}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    {/* **********Hybrid 2********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 3 Rates Type Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 3 Rates Type Hybrid 2"
                        value={"Floating"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 3 Rates in % Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 3 Rates in %"
                        value={thisPackage.y3h2_rates || ""}
                        disabled={true}
                        size="small"
                      />

                      <SoftTypography variant="caption" color="success">
                        {thisPackage.y3h1_rates
                          ? thisPackage.y3h1_rates + "% "
                          : "Year 3 Rates in % "}
                        +{" "}
                        {thisPackage.y3h2_benchmark
                          ? thisPackage.y3h2_benchmark
                          : "Year 3 Rates Benchmark*"}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 3 Rates Benchmark Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        value={
                          benchmarks.find(
                            (item) => item.value == thisPackage.y3h2_benchmark
                          )?.label
                        }
                        size="small"
                        placeholder="Year 3 Rates Benchmark Hybrid 2"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 3 Rates Hybrid 2 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 3 Rates in %"
                        value={thisPackage.y3h2_portion || ""}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                  </SoftBox>
                )}
                {thisPackage.y3_rates_cat !== "hybrid" && (
                  <>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 3 Rates in %
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 3 Rates in %"
                        value={thisPackage.y3_rates || ""}
                        size="small"
                        disabled={true}
                      />
                      {thisPackage.y3_rates_cat === "floating" && (
                        <SoftTypography variant="caption" color="success">
                          {thisPackage.y3_rates + "% "}+{" "}
                          {
                            benchmarks.find(
                              (item) =>
                                item.value == thisPackage.y3_rates_benchmark
                            )?.label
                          }
                        </SoftTypography>
                      )}
                    </SoftBox>
                    {thisPackage.y3_rates_cat === "floating" && (
                      <SoftBox mb={2}>
                        <SoftTypography
                          variant="h6"
                          style={{ marginBottom: "5px" }}
                        >
                          Year 3 Rates Benchmark*
                        </SoftTypography>
                        <SoftInput
                          type="text"
                          value={
                            benchmarks.find(
                              (item) =>
                                item.value == thisPackage.y3_rates_benchmark
                            )?.label || ""
                          }
                          size="small"
                          disabled={true}
                        />
                      </SoftBox>
                    )}
                  </>
                )}
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Year 4 Rates Type
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Year 4 Rates Type"
                    value={
                      RatesTypes.find(
                        (item) => item.value == thisPackage?.y4_rates_cat
                      )?.label || ""
                    }
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                {thisPackage.y4_rates_cat === "hybrid" && (
                  <SoftBox
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "6px",
                      borderRadius: "8px",
                    }}
                  >
                    {/* **********Hybrid 1********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 4 Rates Type Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 4 Rates Type Hybrid 1"
                        value={"Fixed"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 4 Rates in % Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 4 Rates in %"
                        value={thisPackage.y4h1_rates}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 4 Rates Hybrid 1 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 4 Rates Hybrid 1 for % of loan"
                        value={thisPackage.y4h1_portion}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    {/* **********Hybrid 2********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 4 Rates Type Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 4 Rates Type Hybrid 2"
                        value={"Floating"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 4 Rates in % Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 4 Rates in %"
                        value={thisPackage.y4h2_rates || ""}
                        disabled={true}
                        size="small"
                      />

                      <SoftTypography variant="caption" color="success">
                        {thisPackage.y4h1_rates
                          ? thisPackage.y4h1_rates + "% "
                          : "Year 4 Rates in % "}
                        +{" "}
                        {thisPackage.y4h2_benchmark
                          ? thisPackage.y4h2_benchmark
                          : "Year 4 Rates Benchmark*"}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 4 Rates Benchmark Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        value={
                          benchmarks.find(
                            (item) => item.value == thisPackage.y4h2_benchmark
                          )?.label
                        }
                        size="small"
                        placeholder="Year 4 Rates Benchmark Hybrid 2"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 4 Rates Hybrid 2 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 4 Rates in %"
                        value={thisPackage.y4h2_portion || ""}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                  </SoftBox>
                )}
                {thisPackage.y4_rates_cat !== "hybrid" && (
                  <>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 4 Rates in %
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 4 Rates in %"
                        value={thisPackage.y4_rates || ""}
                        size="small"
                        disabled={true}
                      />
                      {thisPackage.y4_rates_cat === "floating" && (
                        <SoftTypography variant="caption" color="success">
                          {thisPackage.y4_rates + "% "}+{" "}
                          {
                            benchmarks.find(
                              (item) =>
                                item.value == thisPackage.y4_rates_benchmark
                            )?.label
                          }
                        </SoftTypography>
                      )}
                    </SoftBox>
                    {thisPackage.y4_rates_cat === "floating" && (
                      <SoftBox mb={2}>
                        <SoftTypography
                          variant="h6"
                          style={{ marginBottom: "5px" }}
                        >
                          Year 4 Rates Benchmark*
                        </SoftTypography>
                        <SoftInput
                          type="text"
                          value={
                            benchmarks.find(
                              (item) =>
                                item.value == thisPackage.y4_rates_benchmark
                            )?.label || ""
                          }
                          size="small"
                          disabled={true}
                        />
                      </SoftBox>
                    )}
                  </>
                )}
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Year 5 Rates Type
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Year 5 Rates Type"
                    size="small"
                    value={
                      RatesTypes.find(
                        (item) => item.value == thisPackage?.y5_rates_cat
                      )?.label || ""
                    }
                    disabled={true}
                  />
                </SoftBox>
                {thisPackage.y5_rates_cat === "hybrid" && (
                  <SoftBox
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "6px",
                      borderRadius: "8px",
                    }}
                  >
                    {/* **********Hybrid 1********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 5 Rates Type Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 5 Rates Type Hybrid 1"
                        value={"Fixed"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 5 Rates in % Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 5 Rates in %"
                        value={thisPackage.y5h1_rates}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 5 Rates Hybrid 1 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 5 Rates Hybrid 1 for % of loan"
                        value={thisPackage.y5h1_portion}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    {/* **********Hybrid 2********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 5 Rates Type Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 5 Rates Type Hybrid 2"
                        value={"Floating"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 5 Rates in % Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 5 Rates in %"
                        value={thisPackage.y5h2_rates || ""}
                        disabled={true}
                        size="small"
                      />

                      <SoftTypography variant="caption" color="success">
                        {thisPackage.y5h1_rates
                          ? thisPackage.y5h1_rates + "% "
                          : "Year 5 Rates in % "}
                        +{" "}
                        {thisPackage.y5h2_benchmark
                          ? thisPackage.y5h2_benchmark
                          : "Year 5 Rates Benchmark*"}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 5 Rates Benchmark Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        value={
                          benchmarks.find(
                            (item) => item.value == thisPackage.y5h2_benchmark
                          )?.label
                        }
                        size="small"
                        placeholder="Year 5 Rates Benchmark Hybrid 2"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 5 Rates Hybrid 2 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 5 Rates in %"
                        value={thisPackage.y5h2_portion || ""}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                  </SoftBox>
                )}
                {thisPackage.y5_rates_cat !== "hybrid" && (
                  <>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 5 Rates in %
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Year 5 Rates in %"
                        value={thisPackage.y5_rates || ""}
                        size="small"
                        disabled={true}
                      />
                      {thisPackage.y5_rates_cat === "floating" && (
                        <SoftTypography variant="caption" color="success">
                          {thisPackage.y5_rates + "% "}+
                          {
                            benchmarks.find(
                              (item) =>
                                item.value == thisPackage.y5_rates_benchmark
                            )?.label
                          }
                        </SoftTypography>
                      )}
                    </SoftBox>
                    {thisPackage.y5_rates_cat === "floating" && (
                      <SoftBox mb={2}>
                        <SoftTypography
                          variant="h6"
                          style={{ marginBottom: "5px" }}
                        >
                          Year 5 Rates Benchmark*
                        </SoftTypography>
                        <SoftInput
                          type="text"
                          value={
                            benchmarks.find(
                              (item) =>
                                item.value == thisPackage.y5_rates_benchmark
                            )?.label || ""
                          }
                          size="small"
                          disabled={true}
                        />
                      </SoftBox>
                    )}
                  </>
                )}
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Thereafter Rates Type*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Thereafter Rates in %"
                    value={
                      RatesTypes.find(
                        (item) =>
                          item.value == thisPackage?.thereafter_rates_cat
                      )?.label || ""
                    }
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                {thisPackage.thereafter_rates_cat === "hybrid" && (
                  <SoftBox
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "6px",
                      borderRadius: "8px",
                    }}
                  >
                    {/* **********Hybrid 1********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Thereafter Rates Type Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Thereafter Rates Type Hybrid 1"
                        value={"Fixed"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Thereafter Rates in % Hybrid 1*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Thereafter Rates in %"
                        value={thisPackage.thereafterh1_rates}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Thereafter Rates Hybrid 1 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Thereafter Rates Hybrid 1 for % of loan"
                        value={thisPackage.thereafterh1_portion}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    {/* **********Hybrid 2********** */}
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Thereafter Rates Type Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Thereafter Rates Type Hybrid 2"
                        value={"Floating"}
                        disabled={true}
                        size="small"
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Thereafter Rates in % Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Thereafter Rates in %"
                        value={thisPackage.thereafterh2_rates || ""}
                        disabled={true}
                        size="small"
                      />

                      <SoftTypography variant="caption" color="success">
                        {thisPackage.thereafterh1_rates
                          ? thisPackage.thereafterh1_rates + "% "
                          : "Thereafter Rates in % "}
                        +{" "}
                        {thisPackage.thereafterh2_benchmark
                          ? thisPackage.thereafterh2_benchmark
                          : "Thereafter Rates Benchmark*"}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Thereafter Rates Benchmark Hybrid 2*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        value={
                          benchmarks.find(
                            (item) =>
                              item.value == thisPackage.thereafterh2_benchmark
                          )?.label
                        }
                        size="small"
                        placeholder="Thereafter Rates Benchmark Hybrid 2"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Thereafter Rates Hybrid 2 for % of loan*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Thereafter Rates in %"
                        value={thisPackage.thereafterh2_portion || ""}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                  </SoftBox>
                )}
                {thisPackage.thereafter_rates_cat !== "hybrid" && (
                  <>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Thereafter Rates in %*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        placeholder="Thereafter Rates in %"
                        value={thisPackage.thereafter_rates || ""}
                        size="small"
                        disabled={true}
                      />
                      {thisPackage.thereafter_rates_cat === "floating" && (
                        <SoftTypography variant="caption" color="success">
                          {thisPackage.thereafter_rates + "% "}+{" "}
                          {
                            benchmarks.find(
                              (item) =>
                                item.value ==
                                thisPackage.thereafter_rates_benchmark
                            )?.label
                          }
                        </SoftTypography>
                      )}
                    </SoftBox>
                    {thisPackage.thereafter_rates_cat === "floating" && (
                      <SoftBox mb={2}>
                        <SoftTypography
                          variant="h6"
                          style={{ marginBottom: "5px" }}
                        >
                          Thereafter Rates Benchmark*
                        </SoftTypography>
                        <SoftInput
                          type="text"
                          value={
                            benchmarks.find(
                              (item) =>
                                item.value ==
                                thisPackage.thereafter_rates_benchmark
                            )?.label || ""
                          }
                          size="small"
                          disabled={true}
                        />
                      </SoftBox>
                    )}
                  </>
                )}
                {(thisPackage.y1_rates_benchmark === "bbr" ||
                  thisPackage.y2_rates_benchmark === "bbr" ||
                  thisPackage.y3_rates_benchmark === "bbr" ||
                  thisPackage.y4_rates_benchmark === "bbr" ||
                  thisPackage.y5_rates_benchmark === "bbr" ||
                  thisPackage.thereafter_rates_benchmark === "bbr") && (
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Board Rate*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Board Rate"
                      value={thisPackage.board_rate || ""}
                      size="small"
                      disabled={true}
                    />
                  </SoftBox>
                )}
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thisPackage.legal_subsidy_cash_rebate == 1}
                          disabled={true}
                        />
                      }
                      label="Legal Subsidy/Cash Rebate"
                      sx={{ marginLeft: "0px" }}
                    />
                  </FormGroup>
                </SoftBox>
                {thisPackage.legal_subsidy_cash_rebate == 1 && (
                  <>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Legal Subsidy/Cash Rebate ($)*
                      </SoftTypography>
                      <SoftInput
                        placeholder="Legal Subsidy/Cash Rebate ($)"
                        value={
                          thisPackage.legal_subsidy_cash_rebate_amount || ""
                        }
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Legal Subsidy/Cash Rebate in clawback period (Years)*
                      </SoftTypography>
                      <SoftInput
                        placeholder="Legal Subsidy/Cash Rebate in clawback period (Years)"
                        value={
                          thisPackage.legal_subsidy_cash_rebate_clawback_years ||
                          ""
                        }
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                  </>
                )}
                <SoftBox mb={2}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thisPackage.fire_insurance == 1}
                          disabled={true}
                        />
                      }
                      label="Fire Insurance Description"
                      sx={{ marginLeft: "0px" }}
                    />
                  </FormGroup>
                </SoftBox>
                <SoftBox mb={2}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thisPackage.free_repricing_conversion == 1}
                          disabled={true}
                        />
                      }
                      label="Free Repricing/ Conversion"
                      sx={{ marginLeft: "0px" }}
                    />
                  </FormGroup>
                </SoftBox>
                {thisPackage.free_repricing_conversion == 1 && (
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Numbers of months for Repricing*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      value={thisPackage.free_repricing_conversion_months || ""}
                      placeholder="Numbers of months for Repricing*"
                      size="small"
                      disabled={true}
                    />
                  </SoftBox>
                )}
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Free Valuation Description
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Free Valuation Description"
                    value={thisPackage.free_valuation || ""}
                    size="small"
                    multiline
                    rows={3}
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thisPackage.partial_prepayment == 1}
                          disabled={true}
                        />
                      }
                      label="Partial Prepayment"
                      sx={{ marginLeft: "0px" }}
                    />
                  </FormGroup>
                </SoftBox>
                {thisPackage.partial_prepayment == 1 && (
                  <>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Numbers of years allowed*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        value={thisPackage.partial_prepayment_years || ""}
                        placeholder="Numbers of years"
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Payment allowed in %*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        value={thisPackage.partial_prepayment_amount || ""}
                        placeholder="Partial Prepayment Payment"
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Penalty in %*
                      </SoftTypography>
                      <SoftInput
                        type="text"
                        value={thisPackage.partial_prepayment_penalty || ""}
                        placeholder="Partial Prepayment Penalty"
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Min. Loan Quantum ($)*
                  </SoftTypography>
                  <SoftInput
                    placeholder="Min. Loan Quantum ($)"
                    value={thisPackage.min_quantum || ""}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Building Under Construction (BUC)*
                  </SoftTypography>
                  <SoftInput
                    value={
                      [
                        { value: null, label: "" },
                        { value: 1, label: "Yes" },
                        { value: 0, label: "No" },
                      ].find((option) => option.value == thisPackage.buc)
                        ?.label || ""
                    }
                    size="small"
                    placeholder="Building Under Construction (BUC)"
                    disabled={true}
                  />
                </SoftBox>
                {thisPackage.buc == 1 && (
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Cancellation Fee in %*
                    </SoftTypography>
                    <SoftInput
                      placeholder="Cancellation Fee"
                      value={thisPackage.buc_cancellation_penalty || ""}
                      size="small"
                      disabled={true}
                    />
                  </SoftBox>
                )}
                <SoftBox mb={2}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thisPackage.credit_card == 1}
                          disabled={true}
                        />
                      }
                      label="Credit card application"
                      sx={{ marginLeft: "0px" }}
                    />
                  </FormGroup>
                </SoftBox>
                <SoftBox mb={2}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thisPackage.savings_account == 1}
                          disabled={true}
                        />
                      }
                      label="Open savings account"
                      sx={{ marginLeft: "0px" }}
                    />
                  </FormGroup>
                </SoftBox>
                {thisPackage.savings_account == 1 && (
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Minimum deposit for savings account
                    </SoftTypography>
                    <SoftInput
                      placeholder="Minimum deposit for savings account"
                      value={thisPackage.savings_account_amount || ""}
                      size="small"
                      disabled={true}
                    />
                  </SoftBox>
                )}
                <SoftBox mb={2}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thisPackage.current_account == 1}
                          disabled={true}
                        />
                      }
                      label="Open current account"
                      sx={{ marginLeft: "0px" }}
                    />
                  </FormGroup>
                </SoftBox>
                {thisPackage.current_account == 1 && (
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Minimum deposit for current account
                    </SoftTypography>
                    <SoftInput
                      placeholder="Minimum deposit for current account"
                      value={thisPackage.current_account_amount || ""}
                      size="small"
                      disabled={true}
                    />
                  </SoftBox>
                )}
                <SoftBox mb={2}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thisPackage.fixed_deposit_account == 1}
                          disabled={true}
                        />
                      }
                      label="Open fixed deposit account"
                      sx={{ marginLeft: "0px" }}
                    />
                  </FormGroup>
                </SoftBox>
                {thisPackage.fixed_deposit_account == 1 && (
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Minimum deposit for fixed deposit account
                    </SoftTypography>
                    <SoftInput
                      placeholder="Minimum deposit for fixed deposit account"
                      value={thisPackage.fixed_deposit_account_amount || ""}
                      size="small"
                      disabled={true}
                    />
                  </SoftBox>
                )}
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Lock-in Period (No. of Years)*
                  </SoftTypography>
                  <SoftInput
                    placeholder="Lock-in Period (No. of Years)"
                    value={thisPackage.lockin_years || ""}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Penalty for Breaking Lock-in (%)*
                  </SoftTypography>
                  <FormControl>
                    <RadioGroup row name="lockin-penalty" disabled={true}>
                      {[0, 0.75, 1, 1.25, 1.5, 1.75].map((value) => (
                        <FormControlLabel
                          key={value}
                          value={value.toString()}
                          control={
                            <Radio
                              checked={value === thisPackage.lockin_penalty}
                              disabled={true}
                            />
                          }
                          label={value.toString()}
                          sx={{ marginLeft: 0 }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Lock-in Penalty Type*
                  </SoftTypography>

                  <FormControl>
                    <RadioGroup row name="lockin-penalty-type">
                      <FormControlLabel
                        value={0}
                        control={
                          <Radio
                            checked={0 === thisPackage.lockin_penalty_type}
                            disabled={true}
                          />
                        }
                        label={"Original Loan Amount"}
                        sx={{ marginLeft: 0 }}
                      />
                      <FormControlLabel
                        value={1}
                        control={
                          <Radio
                            checked={1 === thisPackage.lockin_penalty_type}
                            disabled={true}
                          />
                        }
                        label={"Outstanding Loan Amount"}
                        sx={{ marginLeft: 0 }}
                      />
                    </RadioGroup>
                  </FormControl>
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Waiver Due to Sales*
                  </SoftTypography>
                  <SoftInput
                    value={
                      [
                        { value: null, label: "" },
                        { value: 1, label: "Yes" },
                        { value: 0, label: "No" },
                      ].find(
                        (option) => option.value === thisPackage.lockin_sell
                      )?.label || ""
                    }
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                {thisPackage.lockin_sell == 1 && (
                  <>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        After how many months(If allowed)?
                      </SoftTypography>
                      <SoftInput
                        placeholder="After how many months(If allowed)?"
                        value={thisPackage.lockin_sell_after || ""}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Waiver of penalty (If allowed)(%)
                      </SoftTypography>
                      <SoftInput
                        placeholder="Waiver of penalty (If allowed)(%)"
                        value={thisPackage.lockin_sell_waiver_pct || ""}
                        size="small"
                        disabled={true}
                      />
                    </SoftBox>
                  </>
                )}
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Remarks
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Remarks"
                    value={thisPackage.remarks || ""}
                    size="small"
                    multiline
                    rows={3}
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Features
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Features"
                    value={thisPackage.features || ""}
                    size="small"
                    multiline
                    rows={3}
                    disabled={true}
                  />
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={6}>
                &nbsp;
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <Grid container spacing={3} justifyContent="flex-end">
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => cancel()}
                      variant="gradient"
                      color="error"
                    >
                      Cancel
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() =>
                        navigate(`/pages/loan-packages/edit/${packageId}`)
                      }
                      variant="gradient"
                      color="info"
                    >
                      Edit
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => duplicatePackage()}
                      variant="gradient"
                      color="info"
                    >
                      Duplicate
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </SoftBox>
      ) : (
        <Loading />
      )}
    </DashboardLayout>
  );
}

export default ViewPackage;
