import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiURL } from "commons/apiURL";

import SoftBox from "components/SoftBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import Loading from "components/Loading";
import { useAccessToken } from "auth/auth.helper";

function Reports(props) {
  const { accessToken } = useAccessToken();
  const [users, setUsers] = useState([]);
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const segments = location.split("/");
  let roleName = segments[segments.length - 1];
  let roleId;
  if (roleName === "superadmins") {
    roleId = "rol_1qtH0o5GCMcSvqqV";
  } else if (roleName === "consultants") {
    roleId = "rol_ua7OU2Ahw6EacMao";
  } else if (roleName === "bankers") {
    roleId = "rol_e2HBNQfj7M3Y2C1v";
  }

  const getUsers = async () => {
    try {
      const response = await axios.get(
        apiURL + `/api/getUsersByRole/${roleId}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "prata",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setUsers(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response.data.message
      );
    }
  };

  useEffect(() => {
    if (accessToken) {
      getUsers();
    }
  }, [accessToken]);

  let rows;
  if (users) {
    rows = users.map((u) => ({
      name: [u.picture, u.name],
      "logins count": String(u.logins_count),
      "last login": String(u.last_login),
      email: u.email,
      id: u.user_id,
    }));
  }

  const columns = [
    { name: "name", align: "left" },
    { name: "email", align: "center" },
    { name: "id", align: "center" },
    { name: "logins count", align: "center" },
    { name: "last login", align: "center" },
  ];

  return users ? (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Table columns={columns} rows={rows} />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  ) : (
    <Loading />
  );
}

export default Reports;
