/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import MiniGradientLineChart from "examples/Charts/LineCharts/MiniGradientLineChart";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Data
// import miniGradientLineChartData from "layouts/dashboards/crm/data/miniGradientLineChartData";
import rankingsListData from "layouts/dashboards/crm/data/rankingsListData";

// Images
import ivancik from "assets/images/ivancik.jpg";
import Card from "@mui/material/Card";
import { CardContent, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { getBenchmarks } from "api";
import { useState, useEffect } from "react";
import { useAccessToken } from "auth/auth.helper";

function CRM() {
  const { accessToken } = useAccessToken();
  // const { visitorsChart, incomeChart } = miniGradientLineChartData;
  const [benchmark, setBenchmark] = useState([]);

  useEffect(() => {
    if (accessToken) {
      getBenchmarks(accessToken).then((data) => {
        setBenchmark(data);
      });
    }
  }, [accessToken]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BackgroundBlogCard
                image={ivancik}
                title="No messages"
                description={<>No news is good news?</>}
                buttonText="read more"
                action={{
                  type: "internal",
                  route: "/",
                  label: "read more",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card raised sx={{ p: 2 }}>
              <Typography
                variant="h4"
                align="center"
                style={{ marginBottom: "32px" }}
              >
                SORA Rates For The Day
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Card
                    style={{
                      border: "1px solid #f0f0f0",
                      padding: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" color="#6fc7b6" gutterBottom>
                        <BarChartIcon
                          style={{
                            verticalAlign: "middle",
                            marginRight: "8px",
                          }}
                        />
                        1 Month SORA
                      </Typography>
                      <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        {
                          benchmark.find((b) => b.rate_type === "1msora")
                            ?.rate_value
                        }
                        %
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card
                    style={{
                      border: "1px solid #f0f0f0",
                      padding: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" color="#6fc7b6" gutterBottom>
                        <BarChartIcon
                          style={{
                            verticalAlign: "middle",
                            marginRight: "8px",
                          }}
                        />
                        3 Month SORA
                      </Typography>
                      <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        {
                          benchmark.find((b) => b.rate_type === "3msora")
                            ?.rate_value
                        }
                        %
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card
                    style={{
                      border: "1px solid #f0f0f0",
                      padding: "10px",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" color="#6fc7b6" gutterBottom>
                        <BarChartIcon
                          style={{
                            verticalAlign: "middle",
                            marginRight: "8px",
                          }}
                        />
                        6 Month SORA
                      </Typography>
                      <Typography variant="h4" style={{ fontWeight: "bold" }}>
                        {
                          benchmark.find((b) => b.rate_type === "6msora")
                            ?.rate_value
                        }
                        %
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CRM;
