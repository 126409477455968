/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { Card, Typography, Box, Divider } from "@mui/material";
import { styled } from "@mui/system";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Google";

// Images
import family from "assets/images/loan-supermart-4-scaled.jpg";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  margin: "auto",
  padding: theme.spacing(4),
  boxShadow: theme.shadows[3],
}));

function Basic() {
  return (
    <BasicLayout
      title="Loan$upermart"
      description="Login to the CRM"
      image={family}
    >
      <StyledCard>
        <SoftBox textAlign="center" mb={4}>
          <Typography variant="body1" color="textSecondary">
            Sign in with your Google account to access the CRM
          </Typography>
        </SoftBox>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Socials />
        </Box>

        <Divider sx={{ my: 4 }} />

        <Box textAlign="center">
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Having trouble signing in?
          </Typography>
          <Typography variant="body2">
            Contact support at{" "}
            <a
              href="mailto:support@loansupermart.com"
              style={{ color: "primary.main" }}
            >
              enquiry@loansupermart.com.sg
            </a>
          </Typography>
        </Box>
      </StyledCard>
    </BasicLayout>
  );
}

export default Basic;
