// pages/leads/index.js

import { useEffect, useState } from "react";
import axios from "axios";
import { apiURL } from "commons/apiURL";
import { useNavigate, useLocation } from "react-router-dom";

//layouts
import { Grid, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// misc
import { Commarize } from "commons/Formatting";

// data
import LeadStatuses from "data/LeadStatuses";
import { useAccessToken } from "auth/auth.helper";
import Banks from "data/Banks";

function Leads(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const leadStatus = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const user = props.user;
  let userRole = user["https://sales.loansupermart.sg/roles"][0].toUpperCase();

  // states
  const [thisLeads, setThisLeads] = useState([]);
  const { accessToken } = useAccessToken();

  // const checkuserRole = (userRole) => {
  //   if (userRole.includes("BANKER-")) {
  //     //banker - to return the bank
  //     userRole = userRole.replace("BANKER-", "");
  //     return Banks.find((option) => option.value === userRole).value;
  //   } else if (userRole === "CONSULTANTS") {
  //     //consultant
  //     return userRole.toUpperCase();
  //   } else if (userRole === "SUPER-USER") {
  //     //super-user
  //     return userRole.toUpperCase();
  //   }
  // };

  // const getLeads = async () => {
  //   try {
  //     const response = await axios.get(apiURL + "/api/getLeads");
  //     setThisLeads(response.data);
  //   } catch (error) {
  //     console.error(
  //       "There was an error sending the request:",
  //       error.response.data.message
  //     );
  //   }
  // };

  // const getLeadsById = async (bank) => {
  //   try {
  //     const response = await axios.get(apiURL + `/api/getLead/${bank}`, {
  //       headers: {
  //         "X-API-KEY": process.env.REACT_APP_API_KEY,
  //         "x-app-id": "prata",
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     setThisLeads(response.data);
  //   } catch (error) {
  //     console.error(
  //       "There was an error sending the request:",
  //       error.response.data.message
  //     );
  //   }
  // };

  const getLeadsByStatus = async (leadStatus) => {
    try {
      const response = await axios.get(
        apiURL + `/api/getLeadsByStatus/${leadStatus}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "prata",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setThisLeads(response.data);
    } catch (error) {
      setThisLeads([]);
      console.error(
        "There was an error sending the request:",
        error.response.data.message
      );
    }
  };

  const ViewLead = (id) => {
    if (id) {
      navigate(`/pages/leads/view/${id}`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getLeadsByStatus(leadStatus);
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };
    if (accessToken) {
      fetchData();
    }
  }, [leadStatus, accessToken]);

  return (
    <DashboardLayout>
      <DashboardNavbar absolute />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} xl={12}>
            <SoftButton
              component={""}
              onClick={() => navigate("/pages/leads/new")}
              variant="gradient"
              color="info"
              style={{
                float: "right",
                backgroundImage:
                  "linear-gradient(to top left, #173358, #91d4c7)",
                color: "white",
              }}
            >
              Add New Lead
            </SoftButton>
          </Grid>
          {thisLeads.length > 0 ? (
            thisLeads.map((lead) => (
              <Grid item xs={12} md={6} xl={4} key={lead.lead_id}>
                <Card
                  style={{
                    padding: "20px",
                    boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <SoftButton
                    component={""}
                    onClick={() => ViewLead(lead.lead_id)}
                    variant="gradient"
                    style={{
                      backgroundImage:
                        "linear-gradient(to top left, #173358, #91d4c7)",
                      color: "white",
                    }}
                    size="large"
                  >
                    {lead.name}
                  </SoftButton>
                  <SoftTypography
                    mt={1}
                    variant="caption"
                    style={{ color: "gray" }}
                  >
                    &nbsp;
                  </SoftTypography>
                  <Grid item xs={12} md={12} xl={12}>
                    <SoftBadge
                      badgeContent={
                        LeadStatuses.find((l) => l.value === lead.status).label
                      }
                      color="secondary"
                      style={{ float: "left" }}
                      sx={{ mr: 1 }}
                      container
                    />
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} xl={6}>
                      <Typography variant="h5">Financing Type:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <Typography variant="h5">
                        {lead.financing_type.toUpperCase()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} xl={6}>
                      <Typography variant="h5">Amount:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <Typography variant="h5">
                        $ {Commarize(lead.loan_amount)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={6} xl={6}>
                      <Typography variant="h5">Tenure:</Typography>{" "}
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                      <Typography variant="h5">
                        {lead.tenure_required} months
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                    mt={3}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SoftButton
                      component={""}
                      onClick={() => ViewLead(lead.lead_id)}
                      variant="gradient"
                      color="error"
                    >
                      View Lead
                    </SoftButton>
                  </Grid>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} md={6} xl={4}>
              <Card
                sx={{
                  padding: "20px",
                  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <SoftTypography variant="h4">
                  No Leads In This Phase Yet. Hurry Go Get Some!
                </SoftTypography>
              </Card>
            </Grid>
          )}
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Leads;
