import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import NotificationModal from "components/NotificationModal";
import { Grid, Card } from "@mui/material";
import { useAccessToken } from "auth/auth.helper";
import * as api from "api";

const Index = () => {
  const [packageName, setPackageName] = useState({
    package_name: "",
  });
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const { packageNameId } = useParams();
  const { accessToken } = useAccessToken();

  useEffect(() => {
    if (accessToken) {
      getPackageName(packageNameId);
    }
  }, [accessToken]);

  const getPackageName = async (packageNameId) => {
    try {
      const data = await api.getPackageName(packageNameId, accessToken);
      console.log("API Response:", data);
      if (data) {
        setPackageName(data.packageName);
      } else {
        console.error("Unexpected API response format", data);
      }
    } catch (error) {
      console.error("Failed to fetch package names:", error);
    }
  };

  const updatePackageName = async (packageNameId) => {
    try {
      const data = await api.updatePackageName(
        packageNameId,
        {
          package_name: packageName.package_name,
        },
        accessToken
      );
      console.log("API Response:", data);
      setModal(true);
    } catch (error) {
      console.error("Failed to fetch package names:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {modal && (
        <NotificationModal
          setShowModal={setModal}
          message={"Package Name updated Successfully"}
        />
      )}
      <Card style={{ padding: "28px 40px" }}>
        <SoftBox>
          <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
            Package name
          </SoftTypography>
          <SoftInput
            placeholder="Package name"
            value={packageName.package_name}
            onChange={(e) => {
              setPackageName((prev) => ({
                ...prev,
                package_name: e.target.value,
              }));
            }}
          />
        </SoftBox>
        <Grid container spacing={3} mt={10}>
          <Grid item xs={12} md={6} xl={8}>
            &nbsp;
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Grid container spacing={3} justifyContent="flex-end">
              <Grid item>
                <SoftButton
                  component={""}
                  onClick={() => {
                    navigate("..");
                  }}
                  variant="gradient"
                  color="error"
                >
                  Back
                </SoftButton>
              </Grid>
              <Grid item>
                <SoftButton
                  component={""}
                  onClick={() => {
                    updatePackageName(packageNameId);
                  }}
                  variant="gradient"
                  color="info"
                >
                  Update
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};

export default Index;
